import React, { useEffect, useState } from "react";
import {
  getAppointments,
  getAppointmentsCompanyUser,
  approveAppintments,
  rejectAppointment,
} from "api/company/appointments";
import Card from "components/card";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-toastify";

const AppointmentsForm = () => {
  const [activeTab, setActiveTab] = useState("pending");
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [approveLoading, setApproveLoading] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [appointmentIdToApprove, setAppointmentIdToApprove] = useState(null);
  const [appointmentIdToReject, setAppointmentIdToReject] = useState(null);

  useEffect(() => {
    const companyId = localStorage.getItem("id");
    const token = localStorage.getItem("jwttoken");
    const adminType = localStorage.getItem("adminType");

    const fetchAppointments = async () => {
      try {
        let response;
        if (adminType === "company") {
          response = await getAppointments(companyId, token);
        } else if (adminType === "companyUser") {
          response = await getAppointmentsCompanyUser(companyId, token);
        }
        setAppointments(response.data.data);
        console.log("Fetched Appointments:", response.data.data); // Log the fetched data
        setLoading(false);
      } catch (error) {
        console.error("Error fetching appointments:", error);
        setLoading(false);
      }
    };

    fetchAppointments();
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleApprove = (appointmentId) => {
    setApproveModal(true);
    setAppointmentIdToApprove(appointmentId);
  };

  const handleReject = (appointmentId) => {
    setRejectModal(true);
    setAppointmentIdToReject(appointmentId);
  };

  const confirmApprove = async () => {
    const companyId = localStorage.getItem("id");
    const token = localStorage.getItem("jwttoken");
    const adminType = localStorage.getItem("adminType");
    try {
      setApproveLoading(true);
      if (appointmentIdToApprove) {
        const response = await approveAppintments(appointmentIdToApprove);
        if (response.data.status === 200) {
          toast.success(response.data.message);
          // Refresh the list of appointments after approval
          if (adminType === "company") {
            const updatedAppointments = await getAppointments(companyId, token);
            setAppointments(updatedAppointments.data.data);
          } else if (adminType === "companyUser") {
            const updatedAppointments = await getAppointmentsCompanyUser(
              companyId,
              token
            );
            setAppointments(updatedAppointments.data.data);
          }
        }
      }
      setApproveModal(false);
    } catch (error) {
      console.error("Error approving appointment:", error);
      toast.error("Error approving appointment");
      setApproveModal(false);
    } finally {
      setApproveLoading(false);
    }
  };

  const confirmReject = async () => {
    const companyId = localStorage.getItem("id");
    const token = localStorage.getItem("jwttoken");
    const adminType = localStorage.getItem("adminType");
    try {
      setApproveLoading(true);
      if (appointmentIdToReject) {
        const response = await rejectAppointment(appointmentIdToReject);
        if (response.data.status === 200) {
          toast.success(response.data.message);
          // Refresh the list of appointments after rejection
          if (adminType === "company") {
            const updatedAppointments = await getAppointments(companyId, token);
            setAppointments(updatedAppointments.data.data);
          } else if (adminType === "companyUser") {
            const updatedAppointments = await getAppointmentsCompanyUser(
              companyId,
              token
            );
            setAppointments(updatedAppointments.data.data);
          }
        }
      }
      setRejectModal(false);
    } catch (error) {
      console.error("Error rejecting appointment:", error);
      toast.error("Error rejecting appointment");
      setRejectModal(false);
    } finally {
      setApproveLoading(false);
    }
  };

  return (
    <>
      <Card extra={"w-full h-full my-6 p-4"}>
        <div className="mt-6 flex flex-wrap space-x-4 overflow-hidden">
          <button
            className={`${
              activeTab === "pending" ? "bg-blue-500 text-white" : "bg-gray-300"
            } mb-2 rounded px-4 py-2 sm:mb-0`}
            onClick={() => handleTabClick("pending")}
          >
            Ausstehend
          </button>
          <button
            className={`${
              activeTab === "approved"
                ? "bg-green-500 text-white"
                : "bg-gray-300"
            } mb-2 rounded px-4 py-2 sm:mb-0`}
            onClick={() => handleTabClick("approved")}
          >
            Genehmigt
          </button>
          <button
            className={`${
              activeTab === "rejected" ? "bg-red-500 text-white" : "bg-gray-300"
            } rounded px-4 py-2`}
            onClick={() => handleTabClick("rejected")}
          >
            Abgelehnt
          </button>
        </div>

        {activeTab === "pending" && (
          <div className="container mx-auto mt-8">
            <div className="text-2xl font-semibold">
              <h1 className="rounded border border-b-blue-400 bg-blue-500 p-2 px-4 py-2 text-center text-white">
                Ausstehend
              </h1>
            </div>
            {loading ? (
              <div className="flex h-full items-center justify-center">
                <div className="h-16 w-16 animate-spin rounded-full border-b-2 border-t-2 border-brand-50"></div>
              </div>
            ) : (
              <div className="w-full">
                <div className="mt-8">
                  <div className="max-h-[80vh] overflow-y-auto">
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                      {appointments
                        .filter((appointment) => appointment.pending === true)
                        .map((appointment, index) => (
                          <div
                            key={appointment._id}
                            className="rounded-lg border border-gray-200 p-4 shadow-md"
                          >
                            <div className="flex justify-between">
                              <span className="font-semibold">
                                #{index + 1}
                              </span>
                              <div>
                                <button
                                  onClick={() => handleApprove(appointment._id)}
                                  className="mr-2 rounded-full bg-green-500 p-2 text-white hover:bg-green-700"
                                >
                                  Genehmigen
                                </button>
                                <button
                                  onClick={() => handleReject(appointment._id)}
                                  className="rounded-full bg-red-600 p-2 text-white hover:bg-red-700"
                                >
                                  Ablehnen
                                </button>
                              </div>
                            </div>
                            <div className="mt-4 space-y-1">
                              <p>
                                <strong>Name:</strong> {appointment.firstName}{" "}
                                {appointment.lastName}
                              </p>
                              <p>
                                <strong>E-Mail:</strong> {appointment.email}
                              </p>
                              <p>
                                <strong>Datum der Bewerbung:</strong>{" "}
                                {new Date(
                                  appointment.createdAt
                                ).toLocaleDateString()}
                              </p>
                              <p>
                                <strong>Kategorie:</strong>{" "}
                                {appointment.category}
                              </p>
                              <p>
                                <strong>Deadline:</strong>{" "}
                                <span className="rounded bg-red-600 p-1 text-white">
                                  {new Date(
                                    appointment.deadline
                                  ).toLocaleDateString()}
                                </span>
                              </p>
                              <p>
                                <strong>Alter:</strong> {appointment.age}
                              </p>
                              <p>
                                <strong>Schulname:</strong>{" "}
                                {appointment.schoolName}
                              </p>
                              <p>
                                <strong>Klassenkategorie:</strong>{" "}
                                {appointment.classCategory}
                              </p>
                              <p>
                                <strong>Informationen:</strong>{" "}
                                {appointment.information}
                              </p>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {activeTab === "approved" && (
          <div className="container mx-auto mt-8">
            <div className="text-2xl font-semibold">
              <h1 className="rounded border border-b-green-400 bg-green-500 p-2 px-4 py-2 text-center text-white">
                Genehmigt
              </h1>
            </div>
            {loading ? (
              <div className="flex h-full items-center justify-center">
                <div className="h-16 w-16 animate-spin rounded-full border-b-2 border-t-2 border-brand-600"></div>
              </div>
            ) : (
              <div className="w-full">
                <div className="mt-8 ">
                  <div className="max-h-[80vh] overflow-y-auto">
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                      {appointments
                        .filter(
                          (appointment) => appointment.isApproved === true
                        )
                        .map((appointment, index) => (
                          <div
                            key={appointment._id}
                            className="rounded-lg border border-gray-200 p-4 shadow-md"
                          >
                            <div className="flex justify-between">
                              <span className="font-semibold">
                                #{index + 1}
                              </span>
                            </div>
                            <div className="mt-4 space-y-2">
                              <p>
                                <strong>Name:</strong> {appointment.firstName}{" "}
                                {appointment.lastName}
                              </p>
                              <p>
                                <strong>E-Mail:</strong> {appointment.email}
                              </p>
                              <p>
                                <strong>Status:</strong>{" "}
                                <span className="rounded bg-green-500 p-1 text-white">
                                  Genehmigt
                                </span>
                              </p>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {activeTab === "rejected" && (
          <div className="container mx-auto mt-8">
            <div className="text-2xl font-semibold">
              <h1 className="rounded border border-b-red-500 bg-red-500 p-2 px-4 py-2 text-center text-white">
                Abgelehnt
              </h1>
            </div>
            {loading ? (
              <div className="flex h-full items-center justify-center">
                <div className="h-16 w-16 animate-spin rounded-full border-b-2 border-t-2 border-brand-50"></div>
              </div>
            ) : (
              <div className="w-full">
                <div className="mt-8">
                  <div className="max-h-[80vh] overflow-y-auto">
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                      {appointments
                        .filter((appointment) => appointment.isReject === true)
                        .map((appointment, index) => (
                          <div
                            key={appointment._id}
                            className="rounded-lg border border-gray-200 p-4 shadow-md"
                          >
                            <div className="flex justify-between">
                              <span className="font-semibold">
                                #{index + 1}
                              </span>
                            </div>
                            <div className="mt-4 space-y-2">
                              <p>
                                <strong>Name:</strong> {appointment.firstName}{" "}
                                {appointment.lastName}
                              </p>
                              <p>
                                <strong>E-Mail:</strong> {appointment.email}
                              </p>
                              <p>
                                <strong>Status:</strong>{" "}
                                <span className="rounded bg-red-500 p-1 text-white">
                                  Abgelehnt
                                </span>
                              </p>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </Card>

      {/* Modal for approve confirm */}
      <Transition show={approveModal} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => setApproveModal(false)}
        >
          <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle">
                {approveLoading ? (
                  <div className="flex h-full items-center justify-center">
                    <div className="h-16 w-16 animate-spin rounded-full border-b-2 border-t-2 border-brand-600"></div>
                  </div>
                ) : (
                  <>
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                          <svg
                            className="h-6 w-6 text-green-600"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900"
                          >
                            Genehmigung bestätigen
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Sind Sie sicher, dass Sie diese Ernennung
                              genehmigen wollen?
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-white px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        onClick={confirmApprove}
                        type="button"
                        className="border-transparent inline-flex w-full justify-center rounded-md border bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                      >
                        Genehmigen
                      </button>
                      <button
                        onClick={() => setApproveModal(false)}
                        type="button"
                        className="hover:bg-gray-50 mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                      >
                        Abbrechen
                      </button>
                    </div>
                  </>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

      {/* Modal for Reject confirm */}
      <Transition show={rejectModal} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => setRejectModal(false)}
        >
          <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle">
                {approveLoading ? (
                  <div className="flex h-full items-center justify-center">
                    <div className="h-16 w-16 animate-spin rounded-full border-b-2 border-t-2 border-brand-600"></div>
                  </div>
                ) : (
                  <>
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <svg
                            className="h-6 w-6 text-red-600"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900"
                          >
                            Bestätigen Ablehnen
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Sind Sie sicher, dass Sie diesen Termin ablehnen
                              wollen?
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-white px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        onClick={confirmReject}
                        type="button"
                        className="border-transparent inline-flex w-full justify-center rounded-md border bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                      >
                        Ablehnen
                      </button>
                      <button
                        onClick={() => setRejectModal(false)}
                        type="button"
                        className="hover:bg-gray-50 mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                      >
                        Abbrechen
                      </button>
                    </div>
                  </>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default AppointmentsForm;
